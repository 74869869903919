/*
 * @Author: 智客云网络科技
 * @Date: 2021-11-01 15:00:52
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-01-22 00:01:17
 * @Description: 页面专属接口管理
 * @FilePath: \src\views\App\CustomsDeclaration\api.js
 */

import http from "@/api";

/**************************** 申报单操作 ****************************/

// 获取申报单表格
export const getCustomsDeclarationTable = (params) =>
  http.get("/v1/CustomsDeclaration/GetCustomsDeclarationTable", params);

/**************************** 包裹操作 ****************************/

// 创建新包裹
export const createPackage = (params) =>
  http.post("/v1/Package/CreatePackage", params);

// 删除包裹
export const delPackage = (params) =>
  http.delete("/v1/Package/DelPackage", params);

// 修改包裹信息
export const updatePackage = (params) =>
  http.put("/v1/Package/UpdatePackage", params);

// 根据ID查询包裹信息
export const getPackageInfo = (params) =>
  http.get("/v1/Package/GetPackageInfo", params);

// 获取包裹表格
export const getPackageTable = (params) =>
  http.get("/v1/Package/GetPackageTable", params);

/**************************** 渠道操作 ****************************/

//获取渠道列表
export const getChannelList = (params) =>
  http.get("/v1/Channel/GetChannelList", params);
