<!--
 * @Author: 智客云网络科技
 * @Date: 2021-05-20 11:23:11
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-16 16:10:55
 * @Description: 创建 修改 包裹
 * @FilePath: \src\views\App\CustomsDeclaration\NUCustomsDeclaration.vue
-->
<template>
  <el-dialog
    v-drag2anywhere
    :title="title"
    :visible.sync="isShow"
    width="950px"
    top="10vh"
    append-to-body
    destroy-on-close
    :close-on-click-modal="false"
    @close="_HandleClose()"
    custom-class="full-dialog"
  >
    <div class="vue-box">
      <el-card
        shadow="never"
        v-loading="loading"
        element-loading-text="拼命加载中..."
      >
        <el-form :model="m" :inline="true" ref="m" label-width="100px">
          <div class="from-notes-box">
            <el-form-item
              label="客户姓名："
              style="width: calc(100% - 100px); display: block"
            >
              <el-input
                size="small"
                :readonly="true"
                v-model="kcu_name"
                @click.native="_OpenCustomerSelectModel"
                suffix-icon="xxxx"
              ></el-input>
            </el-form-item>
          </div>

          <div class="from-notes-box">
            <el-form-item label="快件单号：" style="width: calc(100% - 100px)">
              <el-input
                size="small"
                :readonly="true"
                v-model="m.express_id_preview"
                @click.native="_OpenExpressInputModel"
                suffix-icon="xxxx"
              ></el-input>
            </el-form-item>
          </div>

          <div class="from-notes-box">
            <el-form-item
              label="货品列表："
              style="width: calc(100% - 100px); display: block"
            >
              <el-input
                size="small"
                v-model="m.commodity_preview"
                @click.native="_OpenCommoditySelectModel"
                suffix-icon="xxxx"
              ></el-input>
            </el-form-item>
          </div>

          <el-form-item label="发货渠道：" style="width: 45%">
            <el-select size="small" v-model="m.kch_id">
              <el-option
                v-for="item in kch_list"
                :key="item.kch_id"
                :label="item.name"
                :value="item.kch_id"
              >
                <span style="float: left">{{ item.name }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{
                  item.abbrev_name
                }}</span>
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="重量：" style="width: 45%">
            <el-input
              size="small"
              v-model="m.weight"
              suffix-icon="xxxx"
            ></el-input>
          </el-form-item>

          <el-form-item label="包裹状态：" style="width: 45%">
            <el-select size="small" v-model="m.kpa_status">
              <el-option
                v-for="item in kpa_status_list"
                :key="item.id"
                :disabled="item.disabled"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="包装箱型号：" style="width: 45%">
            <el-select size="small" v-model="m.box_size">
              <el-option
                v-for="item in box_size_list"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            label="三边长："
            v-if="m.box_size === 220000"
            style="width: 45%"
          >
            <el-input
              size="small"
              v-model="m.kpai_size"
              suffix-icon="xxxx"
            ></el-input>
          </el-form-item>

          <div class="from-notes-box">
            <el-form-item label="图片列表：" style="width: 90%; display: block">
              <el-upload
                action=""
                :file-list="uploadImgList"
                list-type="picture-card"
                name="image_file"
                :http-request="_ImgUpload"
              >
                <i slot="default" class="el-icon-plus"></i>

                <div slot="file" slot-scope="{ file }">
                  <img
                    class="el-upload-list__item-thumbnail"
                    :src="file.url"
                    :alt="file.name"
                  />
                  <span class="el-upload-list__item-actions">
                    <!-- 查看大图 -->
                    <span
                      class="el-upload-list__item-preview"
                      @click="_ShowPreviewImage()"
                    >
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <!-- 删除图片 -->
                    <span
                      class="el-upload-list__item-delete"
                      @click="_RemoveImage(file)"
                    >
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
            </el-form-item>
          </div>

          <div class="from-notes-box">
            <el-form-item label="描述：" style="width: 90%; display: block">
              <el-input
                size="small"
                v-model="m.notes"
                type="textarea"
                :rows="5"
              ></el-input>
            </el-form-item>
          </div>
        </el-form>

        <el-divider></el-divider>

        <el-button
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="_CreatePackage()"
          v-if="id == 0"
          >提交</el-button
        >
        <el-button
          size="small"
          type="primary"
          icon="el-icon-check"
          @click="_UpdatePackage()"
          v-else
          >保存</el-button
        >
        <el-button size="small" icon="el-icon-close" @click="isShow = false"
          >取消</el-button
        >
      </el-card>

      <!-- 图片预览组件 -->
      <el-image-viewer
        v-if="showViewer"
        zIndex="2010"
        :on-close="_ClosePreviewImage"
        :url-list="
          uploadImgList.map((a) => {
            return a.url;
          })
        "
      />

      <!-- 货品词条选择器 -->
      <commodity-items-select
        :show.sync="show_commodity_select"
        :default_items="commodity_items"
        @confirm-select="_SelectCommodity"
      ></commodity-items-select>

      <!-- 客户选择器 -->
      <customer-select
        :show.sync="show_customer_select"
        @confirm-select="_SelectCustomer"
      ></customer-select>

      <!-- 快递单号输入器 -->
      <express-input
        :show.sync="show_express_input"
        :default_list="kec_list"
        @confirm-select="_EnterExpressInput"
      ></express-input>
    </div>
  </el-dialog>
</template>

<script>
import {
  createPackage,
  updatePackage,
  getPackageInfo,
  getChannelList,
} from "./api"; //页面专有接口
import { uploadImage } from "@/api/api"; //公共api 图像文件上传接口
import ElImageViewer from "element-ui/packages/image/src/image-viewer"; //图片查看器
import CommodityItemsSelect from "@/components/commodityItemsSelect"; //货品选择器
import CustomerSelect from "@/components/customerSelect"; //客户选择器
import ExpressInput from "./ExpressInput";
export default {
  components: {
    ElImageViewer,
    CommodityItemsSelect,
    CustomerSelect,
    ExpressInput,
  },
  data() {
    return {
      m: {
        express_id: "", //快件单号
        express_id_preview: "", //快递单号预览
        commodity_preview: "", //物品明细
        kch_id: "", //渠道ID
        kpa_status: "220111", //包裹状态
        weight: "", //重量
        box_size: 220015, //包装箱型号
        kpai_size: "", //三边长度和
        notes: "", //描述
      },
      id: 0, //
      title: "", //弹窗标题
      isShow: false, //显示控制
      loading: true, //加载控制

      //客户信息
      show_customer_select: false, //控制客户选择器显示
      kcu_id: "", //客户ID
      kcu_name: "", //客户姓名

      //快递单号列表
      show_express_input: false, //控制快递单号输入器
      kec_list: [],

      //货品选择控制
      show_commodity_select: false, //货品选择器控制
      commodity_items: [], //货品列表

      //发货渠道列表
      kch_list: [],

      //纸箱型号列表
      box_size_list: [
        { id: 220001, name: "一号箱-3l[60cm]" },
        { id: 220005, name: "二号箱-3l[80cm]" },
        { id: 220010, name: "三号箱-3l[100cm]" },
        { id: 220015, name: "四号箱-3l[120cm]" },
        { id: 220020, name: "五号箱-3l[150cm]" },
        { id: 220025, name: "六号箱-3l[180cm]" },
        { id: 220030, name: "七号箱-3l[200cm]" },
        { id: 220000, name: "非标准箱" },
      ],

      //包裹状态列表
      kpa_status_list: [
        { id: 220111, name: "待出库", disabled: false },
        { id: 220115, name: "分拣中", disabled: false },
        { id: 220120, name: "分发中", disabled: false },
        { id: 220125, name: "海关申报", disabled: false },
        { id: 220130, name: "承运商已收件", disabled: false },
        { id: 220135, name: "已发出", disabled: false },
      ],

      //图片上传
      uploadImgList: [], //上传图片列表
      showViewer: false, // 显示查看器
    };
  },
  methods: {
    // 打开
    open(id) {
      this.isShow = true; //显示组件
      this.id = id; //重写ID
      this._GetChannelList(); //获取渠道列表
      this.$nextTick(() => {
        if (id === 0) {
          //新建
          this.title = "创建新包裹";
          this.loading = false;
        } else {
          //修改
          this._GetPackageInfo(id);
          this.title = "修改包裹信息";
        }
      });
    },

    //创建新包裹
    _CreatePackage() {
      let m = this.m;
      createPackage({
        kcu_id: this.kcu_id, //客户ID
        kec_list: JSON.stringify(this.kec_list), //快件列表
        items: JSON.stringify(this.commodity_items), //货品列表
        box_size: m.box_size, //包装箱型号
        kpai_size: m.kpai_size, //包装箱尺寸
        kpa_status: m.kpa_status, //包裹状态
        weight: m.weight, //包裹重量
        imgs: JSON.stringify(this.uploadImgList), //图片列表
        kch_id: m.kch_id, //渠道ID
        notes: m.notes, //描述
      })
        .then((res) => {
          this.$utils.success(res.err_msg);
          this.isShow = false;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //修改包裹信息
    _UpdatePackage() {
      let m = this.m;
      updatePackage({
        kpa_id: this.id, //包裹ID
        kcu_id: this.kcu_id, //客户ID
        kec_list: JSON.stringify(this.kec_list), //快件列表
        items: JSON.stringify(this.commodity_items), //货品列表
        box_size: m.box_size, //包装箱型号
        kpai_size: m.kpai_size, //包装箱尺寸
        kpa_status: m.kpa_status, //包裹状态
        weight: m.weight, //包裹重量
        imgs: JSON.stringify(this.uploadImgList), //图片列表
        kch_id: m.kch_id, //渠道ID
        notes: m.notes, //描述
      })
        .then((res) => {
          this.$utils.success(res.err_msg);
          this.isShow = false;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //获取包裹信息
    _GetPackageInfo(kpa_id) {
      getPackageInfo({ kpa_id })
        .then((res) => {
          let m = this.m;
          let d = res.data;
          m.box_size = d.box_size; //包装箱型号
          m.kpai_size = d.kpai_size; //包装箱尺寸
          m.kpa_status = d.status; //包裹状态
          m.weight = d.weight; //包裹重量
          this.uploadImgList = JSON.parse(d.img_list); //图片列表
          m.kch_id = d.kch_id; //渠道ID
          m.notes = d.notes; //描述

          //禁用选项
          if (d.status > 220111) {
            this._ModifyStatus(220111, true);
          }

          if (d.status > 220115) {
            this._ModifyStatus(220115, true);
          }

          if (d.status > 220120) {
            this._ModifyStatus(220120, true);
          }

          if (d.status > 220125) {
            this._ModifyStatus(220125, true);
          }

          //设置客户信息
          this._SelectCustomer(JSON.parse(d.kcu_info));

          //设置快件选择
          this._EnterExpressInput(JSON.parse(d.kec_list));

          //设置货品选择
          this._SelectCommodity(JSON.parse(d.items_list));

          this.loading = false;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //图片上传
    _ImgUpload(file) {
      let formData = new FormData(); // 声明一个FormData对象
      formData.append(file.filename, file.file, file.file.name);
      uploadImage(formData)
        .then((res) => {
          let urs = res.data.split("/"); //分割文件地址得到数组
          //同步文件列表
          this.uploadImgList.push({
            name: urs[urs.length - 1], //文件名
            url: process.env.VUE_APP_BASE_URL + "/resources/" + res.data, //文件路径
          });
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //删除图片
    _RemoveImage(file) {
      this.uploadImgList = this.$utils.arrayDelete(this.uploadImgList, file);
    },

    //查看大图
    _ShowPreviewImage() {
      this.showViewer = true;
    },

    //关闭大图预览
    _ClosePreviewImage() {
      this.showViewer = false;
    },

    //获取渠道列表
    _GetChannelList() {
      getChannelList()
        .then((res) => {
          this.kch_list = res.data;
          this.m.kch_id = res.data[0]["kch_id"];
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //打开货品选择器
    _OpenCommoditySelectModel() {
      this.show_commodity_select = true;
    },

    //确认货品选择
    _SelectCommodity(items) {
      this.commodity_items = items;
      this.m.commodity_preview = ""; //清空货品明细预览
      //创建货品明细预览
      var ai = 0;
      for (var i = 0; i < items.length; i++) {
        let item = items[i];
        let commodity = this.m.commodity_preview;

        if (i < 5) {
          if (i > 0) {
            commodity = this.m.commodity_preview + ", ";
          }
          commodity = commodity += item.name + " *" + item.amount;

          this.m.commodity_preview = commodity; //回写
        } else {
          ai = ai + item.amount; //计数
          if (i === items.length - 1) {
            this.m.commodity_preview =
              this.m.commodity_preview + "及其他" + ai + "件物品";
          }
        }
      }
    },

    //打开客户选择模块
    _OpenCustomerSelectModel() {
      this.show_customer_select = true;
    },

    //确认客户选择
    _SelectCustomer(customer_info) {
      let ci = customer_info;
      this.kcu_id = ci.kcu_id;
      this.kcu_name = ci.kcu_name;
    },

    //打开快件输入模块
    _OpenExpressInputModel() {
      this.show_express_input = true;
    },

    //确认快件输入
    _EnterExpressInput(kex_list) {
      this.kec_list = kex_list; //写入单号列表
      this.m.express_id_preview = ""; //清空预览
      let exp_id_pr = ""; //初始化
      let add_index = 0; //初始化
      for (let i = 0; i < kex_list.length; i++) {
        if (i < 3) {
          if (i > 0) {
            exp_id_pr += ", ";
          }
          exp_id_pr += kex_list[i]["express_id"];
        } else {
          add_index++;
          if (i === kex_list.length - 1) {
            exp_id_pr += " +" + add_index + "个包裹";
          }
        }
      }
      this.m.express_id_preview = exp_id_pr;
    },

    //修改列表项状态
    _ModifyStatus(id, status) {
      let list = this.kpa_status_list;
      for (let i = 0; i < list.length; i++) {
        if (list[i]["id"] === id) {
          list[i]["disabled"] = status;
        }
      }
      this.kpa_status_list = list;
    },

    //关闭
    _HandleClose() {
      Object.assign(this.$data, this.$options.data());
      this.$parent._Load(); //通知父组件刷新数据
    },
  },
  created() {},
};
</script>

<style>
/* 定义图片上传组件样式 */
.el-upload--picture-card {
  width: 90px;
  height: 90px;
  line-height: 100px;
}
.el-upload-list--picture-card .el-upload-list__item {
  width: 90px;
  height: 90px;
}

.el-upload-list__item > div {
  width: 100%;
  height: 100%;
}

.el-upload-list--picture-card .el-upload-list__item-thumbnail {
  object-fit: cover;
}

.el-upload-list--picture-card .el-upload-list__item-actions span + span {
  margin-left: 10px;
}
</style>
